<template>
    <div class="useragreement">
        <p>用户协议</p>
        <h2>凯迪网络用户账号管理规定</h2>
        <p></p>
        <p><b>第一章 总则</b></p>
        <p>（一）为加强对互联网用户账号名称的管理，保护公民、法人和其他组织的合法权益，根据国家互联网信息办公室发布的《互联网用户账号名称管理规定》和有关法律、行政法规，制定本规定。</p>
        <p>（二）本规定所称凯迪网络用户，是指机构或个人在凯迪网络、凯迪社区和跟帖评论等服务中注册或使用的账号名称。</p>
        <p>（三）凯迪网络对平台下所有账号名称的注册、使用实施监督管理。</p>
        <p>（四）注册用户享有社区用户应有的权利，用户在注册ID和行使社区权利时不得违反国家的法律法规以及社区的各项规定。</p>
        <p><b>第二章 用户账号注册</b></p>
        <p>用户可通过以下方式注册成为凯迪网的用户：</p>
        <p>1、经本网站用户系统完成注册程序并通过实名制认证的用户；</p>
        <p>2、经本网站批准的第三方合作网站登录的非实名制认证用户；</p>
        <p>（一）新注册用户应同意《凯迪网用户协议》和《凯迪网隐私政策》。</p>
        <p>
            （二）按照《互联网论坛社区服务管理规定》，按照“后台实名、前台自愿”的原则，目前认定方法为：以实名制手机号注册或绑定账号即可视为实名制注册用户。
            成为凯迪网实名制认证用户即获得本网站用户所应享有的一切权限；未经实名制认证用户仅享有本网站规定的部分权限。
        </p>
        <p>（三）注册用户在账号昵称、头像和简介等注册信息中不得出现违法和不良信息。</p>
        <p>（四）注册用户承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。</p>
        <p>（五）任何机构或个人注册和使用的本站社区用户账号名称，不得有下列情形：</p>
        <p>1、违反宪法或法律法规规定的；</p>
        <p>2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>3、损害国家荣誉和利益的，损害公共利益的；</p>
        <p>4、煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>5、破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>6、散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>8、侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>9、含有法律、行政法规禁止的其他内容的。</p>
        <p><b>第三章用户分级管理</b></p>
        <p>（一）本站用户分为普通用户，会员用户和大V（凯迪号）认证用户。</p>
        <p>（二）符合以下条件的普通用户和会员用户可向凯迪提交相关资料申请大V（凯迪号）认证，工作人员审核通过后即可享受大V（凯迪号）认证权益</p>
        <p>1、凯迪网平台注册用户；</p>
        <p>2、在凯迪网平台上发表的文章，总阅读数不低于0；</p>
        <p>3、凯迪社区会员优先；</p>
        <p>4、内容优质、无抄袭、未发布过不雅内容、未违反国家有关政策法规等违规记录、且原创比例不低于70%；</p>
        <p>（三）大V（凯迪号）认证用户可享有以下权益</p>
        <p>1、可获得“大V（凯迪号）”徽章标识；</p>
        <p>2、文章、视频创作收益；</p>
        <p>3、帖子、文章、视频获赏收益；</p>
        <p>4、作者推荐</p>
        <p>5、流量扶持，在首页推荐中获得更多流量曝光；</p>
        <p>6、更有效的树立个人品牌，凯迪生态圈内更多的资源倾斜；</p>
        <p>7、参与互动和凯迪网定期组织的线下作者沙龙，并有机会以优先权参与BT传媒举办的各种丰富的线下活动；</p>
        <p>
            （四）对于大V（凯迪号）认证用户，平台每月将复核其发布的文章内容质量，质量保持/提升的作者将继续享有认证作者权益，质量有下滑趋势的作者将会被警告提醒，质量严重下滑的作者将会被取消其作者认证。
        </p>
        <p><b>第四章审核规范</b></p>
        <p>（一）现有的审核包括机器审核和人工审核，机器审核为主，人工审核为辅。机器和人工会对文章进行过滤，根据文章具体情况，决定是否展示给用户。</p>
        <p>（二）文章进入审核流程后，如果被机器确认为含有重复内容（与平台上更早时间发布的某篇文章几乎一致）、违规内容时则不予发布展示。</p>
        <p>
            （三）当文章通过了机器审核之后，经机器筛选出来疑似违规作品以及容易出现在违规领域的作品，凯迪审核人员将进行逐个细致审核，如果确定是违规，那将根据违规账号进行删除文章、降权、通告、封号等处罚。
        </p>
        <p>（四）如未通过审核，文章将不予展示给用户，只有通过审核后，才会展示给平台用户。</p>
        <p><b>第五章 用户禁言</b></p>
        <p>（一）用户账号发布内容谩骂、攻击其他用户，可依照版规进行警告或禁言该版发言权等处理，情节严重者全面禁言处理。</p>
        <p>（二）用户账号进行攻击、侮辱、挑衅、猥亵、诽谤、抄袭等行为的，社区有权全面禁言该用户账号。</p>
        <p>（三）用户账号未经社区同意私自在各个平台和版块发布广告信息者，社区有权全面禁言该用户账号。</p>
        <p>（四）用户账号发布违法违规信息，或违反社区生态规定发布刷屏信息的，社区有权禁言该用户账号。</p>
        <p><b>第六章 用户账号注销</b></p>
        <p>（一）用户以虚假信息骗取账号名称注册，或其账号头像、简介等注册信息存在违法和不良信息的，社区有权通知限期改正、暂停使用、注销账号等措施。</p>
        <p>（二）对冒用、关联机构或社会名人注册账号名称的，社区有权注销其账号。</p>
        <p>（三）注册与已有用户账号相近或相似的账号，社区优先保护先注册的账号，保留对后注册账号注销的权利。</p>
        <p>（四）用户账号注册后，可在个人中心自行注销账号，账号注销后不可再使用。</p>
        <p>（五）注册含有攻击、侮辱、挑衅、猥亵、诽谤含义或带有广告性质的账号，社区有随时注销其账号的权利。</p>
        <p>（六）用户账号不符合社区安全管理要求的，社区可酌情处理，保留注销该用户ID的权利。</p>
        <p><b>第七章 其他</b></p>
        <p>（一）本规定所涉及的内容，凡法律、行政法规或者社区公约有规定的，从其规定。</p>
        <p>（二）本规定解释权及修订权归凯迪网络信息科技有限公司所有。</p>
        <p></p>
        <p></p>
        <h2><p>凯迪网络信息内容生态治理规定</p></h2>
        <p></p>
        <p></p>
        <p><strong>第一章 总则</strong></p>
        <p>
            <strong>第一条</strong
            >&nbsp;为了营造凯迪良好网络生态，保障公民、用户和其他组织的合法权益，维护国家安全和公共利益，根据《中华人民共和国国家安全法》《中华人民共和国网络安全法》《互联网信息服务管理办法》《网络信息内容生态治理规定》等法律、行政法规，制定本规定。
        </p>
        <p><strong>第二条</strong>&nbsp;凯迪网络信息技术（海南）有限公司旗下所有信息内容适用本规定，包括PC端、客户端、小程序等内容、广告、技术产品，以及微信、微博等自媒体平台。</p>
        <p>
            本规定所称网络信息内容生态治理，是指凯迪网络官方与其所有产品用户，以培育和践行社会主义核心价值观为根本，以网络信息内容为主要治理对象，以建立健全网络综合治理体系、营造清朗的网络空间、建设良好的网络生态为目标，开展的弘扬正能量、处置违法和不良信息等相关活动。
        </p>
        <p><strong>第三条</strong>&nbsp;凯迪网络内容总部负责统筹协调凯迪网络信息内容生态治理和相关监督管理工作，运营、技术等有关主管部门依据各自职责做好网络信息内容生态治理工作。</p>
        <p><strong>第二章 凯迪网络信息内容生产者</strong></p>
        <p><strong>第四条</strong>&nbsp;凯迪网络信息内容生产者应当遵守法律法规，遵循公序良俗，不得损害国家利益、公共利益和他人合法权益。</p>
        <p><strong>第五条</strong>&nbsp;鼓励凯迪网络信息内容生产者制作、复制、发布含有下列内容的信息：</p>
        <p>(一)宣传习近平新时代中国特色社会主义思想，全面准确生动解读中国特色社会主义道路、理论、制度、文化的；</p>
        <p>(二)宣传党的理论路线方针政策和中央重大决策部署的；</p>
        <p>(三)展示经济社会发展亮点，反映人民群众伟大奋斗和火热生活的；</p>
        <p>(四)弘扬社会主义核心价值观，宣传优秀道德文化和时代精神，充分展现中华民族昂扬向上精神风貌的；</p>
        <p>(五)有效回应社会关切，解疑释惑，析事明理，有助于引导群众形成共识的；</p>
        <p>(六)有助于提高中华文化国际影响力，向世界展现真实立体全面的中国的；</p>
        <p>(七)其他讲品味讲格调讲责任、讴歌真善美、促进团结稳定等的内容。</p>
        <p><strong>第六条</strong>&nbsp;凯迪网络信息内容生产者不得制作、复制、发布含有下列内容的违法信息：</p>
        <p>(一)反对宪法所确定的基本原则的；</p>
        <p>(二)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>(三)损害国家荣誉和利益的；</p>
        <p>(四)歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
        <p>(五)宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
        <p>(六)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>(七)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>(八)散布谣言，扰乱经济秩序和社会秩序的；</p>
        <p>(九)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>(十)侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
        <p>(十一)法律、行政法规禁止的其他内容。</p>
        <p><strong>第七条</strong>&nbsp;凯迪网络信息内容生产者应当防范和抵制制作、复制、发布含有下列内容的不良信息：</p>
        <p>(一)使用夸张标题，内容与标题严重不符的；</p>
        <p>(二)炒作绯闻、丑闻、劣迹等的；</p>
        <p>(三)不当评述自然灾害、重大事故等灾难的；</p>
        <p>(四)带有性暗示、性挑逗等易使人产生性联想的；</p>
        <p>(五)展现血腥、惊悚、残忍等致人身心不适的；</p>
        <p>(六)煽动人群歧视、地域歧视等的；</p>
        <p>(七)宣扬低俗、庸俗、媚俗内容的；</p>
        <p>(八)可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；</p>
        <p>(九)其他对网络生态造成不良影响的内容。</p>
        <p><strong>第三章 凯迪网络服务平台</strong></p>
        <p><strong>第八条</strong>&nbsp;凯迪网络及旗下所有内容产品平台应积极履行信息内容管理主体责任，加强本平台网络信息内容生态治理，培育积极健康、向上向善的网络文化。</p>
        <p>
            <strong>第九条</strong
            >&nbsp;凯迪网络根据《网络信息内容生态治理规定》修订本规定，持续健全用户注册、账号管理、信息发布审核、跟帖评论审核、版面页面生态管理、实时巡查、应急处置和网络谣言、黑色产业链信息处置等制度。
        </p>
        <p>根据业务范围和服务规模，凯迪网络需配备相适应的专业人员，专岗专责，并加强培训考核，提升从业人员素质。</p>
        <p>
            <strong>第十条</strong
            >&nbsp;凯迪网络各信息内容服务产品应加强信息内容管理，不得传播本规定第六条规定的信息，坚决防范和抵制传播本规定第七条规定的信息。发现本规定第六条、第七条规定的信息的，应当依法立即采取处置措施，保存有关记录，并向有关主管部门报告。
        </p>
        <p>
            <strong>第十一条</strong
            >&nbsp;凯迪网络各信息内容服务产品应坚持主流价值导向，优化信息推荐机制，加强版面页面生态管理，在下列重点环节(包括服务类型、位置版块等)积极呈现本规定第五条规定的信息：
        </p>
        <p>(一)凯迪网络社区首页首屏、榜单类、弹窗和重要新闻信息内容页面等；</p>
        <p>(二)凯迪网络用户账号信息服务精选、热搜、热帖等；</p>
        <p>(三)凯迪网络类如网络游戏、凯迪小说等服务首页首屏、精选、榜单类、弹窗等；</p>
        <p>(四)其它处于产品或者服务醒目位置、易引起网络信息内容服务使用者关注的重点环节。</p>
        <p>凯迪网络各内容服务平台不得在以上重点环节呈现本规定第七条规定的信息。</p>
        <p><strong>第十二条</strong>&nbsp;凯迪网络热帖榜、分享榜、赞赏榜等根据算法推荐技术推送信息的产品，应当设置符合本规定第十条、第十一条规定要求的推荐模型，建立健全人工干预和用户自主选择机制。</p>
        <p>
            <strong>第十三条</strong>&nbsp;凯迪网络应根据业务发展需要，在有关未成年人的产品服务上开发适合未成年人使用的模式，提供适合未成年人使用的网络产品和服务，便利未成年人获取有益身心健康的信息。
        </p>
        <p><strong>第十四条</strong>&nbsp;凯迪网络运营中心应当加强对本平台设置的广告位和在本平台展示的广告内容的审核巡查，对发布违法广告的，应当依法依规予以处理。</p>
        <p>
            <strong>第十五条</strong
            >&nbsp;凯迪网络应当制定并公开管理规则和平台公约，完善用户协议，明确用户相关权利义务，并依法依约履行相应管理职责。建立用户账号信用管理制度，根据用户账号的信用情况提供相应服务。
        </p>
        <p><strong>第十六条</strong>&nbsp;凯迪网络应当在显著位置设置便捷的投诉举报入口，公布投诉举报方式，及时受理处置公众投诉举报并反馈处理结果。</p>
        <p><strong>第十七条</strong>&nbsp;凯迪网络应当编制网络信息内容生态治理工作年度报告，年度报告应当包括网络信息内容生态治理工作情况、网络信息内容生态治理负责人履职情况、社会评价情况等内容。</p>
        <p><strong>第四章 凯迪网络信息内容服务使用者</strong></p>
        <p>
            <strong>第十八条</strong
            >&nbsp;凯迪网络信息内容服务使用者应当文明健康使用网络，按照法律法规的要求和用户协议约定，切实履行相应义务，在以发帖、回复、留言等形式参与网络活动时，文明互动，理性表达，不得发布本规定第六条规定的信息，防范和抵制本规定第七条规定的信息。
        </p>
        <p>
            <strong>第十九条</strong
            >&nbsp;论坛社区版块建立者和管理者，以及拥有跟帖管理权限的帖主，应当履行版块管理责任和单贴清理责任，依据法律法规、用户协议和平台公约等，规范版块内、单贴内信息发布等行为。
        </p>
        <p><strong>第二十条</strong>&nbsp;鼓励凯迪网络信息内容服务使用者积极参与网络信息内容生态治理，通过投诉、举报等方式对网上违法和不良信息进行监督，共同维护良好网络生态。</p>
        <p>
            <strong>第二十一条</strong
            >&nbsp;凯迪网络信息内容服务使用者和网络信息内容生产者、各网络信息内容服务产品不得利用网络和相关信息技术实施侮辱、诽谤、威胁、散布谣言以及侵犯他人隐私等违法行为，损害他人合法权益。
        </p>
        <p><strong>第二十二条</strong>&nbsp;凯迪网络信息内容服务使用者和网络信息内容生产者、各网络信息内容服务产品不得通过发布、删除信息以及其他干预信息呈现的手段侵害他人合法权益或者谋取非法利益。</p>
        <p><strong>第二十三条</strong>&nbsp;凯迪网络信息内容服务使用者和网络信息内容生产者、各网络信息内容服务产品不得利用深度学习、虚拟现实等新技术新应用从事法律、行政法规禁止的活动。</p>
        <p>
            <strong>第二十四条</strong
            >&nbsp;凯迪网络信息内容服务使用者和网络信息内容生产者、各网络信息内容服务产品不得通过人工方式或者技术手段实施流量造假、流量劫持以及虚假注册账号、非法交易账号、操纵用户账号等行为，破坏网络生态秩序。
        </p>
        <p>
            <strong>第二十五条</strong
            >&nbsp;凯迪网络信息内容服务使用者和网络信息内容生产者、各网络信息内容服务产品不得利用党旗、党徽、国旗、国徽、国歌等代表党和国家形象的标识及内容，或者借国家重大活动、重大纪念日和国家机关及其工作人员名义等，违法违规开展网络商业营销活动。
        </p>
        <p><strong>第五章 监督管理</strong></p>
        <p><strong>第二十六条</strong>&nbsp;凯迪网络内容总部会同运营部门、技术部门等，建立健全工作机制，协同开展网络信息内容生态治理工作。</p>
        <p><strong>第二十七条</strong>&nbsp;凯迪网络各部门应切实履行信息内容管理主体责任，根据各部门工作实际，制定适合本部门信息内容生态治理的方案方法、奖惩制度，并予以贯彻落实、形成长效机制。</p>
        <p><strong>第二十八条</strong>&nbsp;凯迪网络各部门对网信部门和有关主管部门依法实施的监督检查，应当予以配合。</p>
        <p><strong>第六章 法律责任</strong></p>
        <p>
            <strong>第二十九条</strong
            >&nbsp;凯迪网络信息内容生产者违反本规定第六条规定的，我司应当依法依约采取警告、限制功能、暂停更新、关闭账号等处置措施，及时消除违法信息内容，保存记录并向有关主管部门报告。
        </p>
        <p>
            <strong>第三十条</strong
            >&nbsp;凯迪网络信息内容服务各产品违反《网络信息内容生态治理规定》第十条、第三十一条第二款规定的，当依据《凯迪网络信息安全问责制度》由总编室依据职责追究责任人，予以处理。
        </p>
        <p>
            <strong>第三十一条</strong
            >&nbsp;凯迪网络信息内容服务各产品违反《网络信息内容生态治理规定》第十一条第二款规定的，当依据《凯迪网络信息安全问责制度》由总编室依据职责追究责任人，按规定予以处理。
        </p>
        <p>
            <strong>第三十二条</strong
            >&nbsp;凯迪网络信息内容服务各产品违反《网络信息内容生态治理规定》第九条、第十二条、第十五条、第十六条、第十七条规定的，当依据《凯迪网络信息安全问责制度》由总编室依据职责追究责任人，按规定予以处理。
        </p>
        <p>
            <strong>第三十三条</strong
            >&nbsp;违反《网络信息内容生态治理规定》第十四条、第十八条、第十九条、第二十一条、第二十二条、第二十三条、第二十四条、第二十五条规定的，当依据《凯迪网络信息安全问责制度》由总编室依据职责追究责任人，按规定予以处理。
        </p>
        <p>
            <strong>第三十四条</strong
            >&nbsp;凯迪网络根据法律、行政法规和国家有关规定，配合有关主管部门建立健全网络信息内容服务严重失信联合惩戒机制，对严重违反《网络信息内容生态治理规定》的网络信息内容生产者和网络信息内容使用者依法依规网上行为限制、行业禁入等惩戒措施。
        </p>
        <p><strong>第三十五条</strong>&nbsp;违反本规定，给他人造成损害的，依法承担民事责任；构成犯罪的，依法追究刑事责任；尚不构成犯罪的，由有关主管部门依照有关法律、行政法规的规定予以处罚。</p>
        <p><strong>第七章 附则</strong></p>
        <p><strong>第三十六条</strong>&nbsp;本规定所称凯迪网络信息内容生产者是指以文字、图片、音频、视频等形式，在凯迪网络旗下产品中制作、复制、发布内容信息的用户，包括组织或者个人。</p>
        <p>本规定所称凯迪网络信息内容服务使用者，是指使用凯迪网络信息内容服务的组织或者个人。</p>
        <p><strong>第三十七条</strong>&nbsp;本规定自2020年5月1日起施行。</p>
        <p></p>
        <p></p>
        <h2>删帖须知：</h2>
        <p></p>
        <p></p>
        <p><b>声明:</b></p>
        <p>依照法律规定删除违法信息是凯迪社区的法定义务，当事方不需要委托第三方进行投诉，凯迪社区亦未与任何中介机构合作开展此项业务。</p>
        <p>为了您的删帖申请能及时被受理，请先阅读以下注意事项：</p>
        <p>1、此为凯迪社区唯一的官方的侵权投诉删帖渠道，暂不提供其他方式处理此业务；</p>
        <p>2、为了保证您的问题能够及时有效的处理，请您务必提交真实有效、完整清晰的材料，否则不予受理；</p>
        <p>3、处理期间，不提供任何电话、邮件及其他方式的查询服务，请谅解；</p>
        <p>4、处理时限：自快递签收之日起10个工作日。请您递交申请及相关材料后，自行刷新帖子页面查看处理结果；</p>
        <p>5、出于媒体舆论监督属性，并非所有申请都必须受理，请谅解；</p>
        <p>6、规范的投诉流程能够使社区更加便捷的处理和有效的管理，希望用户朋友能够严格遵守。</p>
        <p><b>一、受理范围</b></p>
        <p>1、受理凯迪社区内涉及泄露隐私、造谣、诽谤、严重人身攻击等删帖申请，除此之外一概不予受理。</p>
        <p>① 泄露个人隐私：帖子内容中直接涉及个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；</p>
        <p>② 造谣、诽谤、严重人身攻击：帖子内容中指名道姓的直接谩骂、侮辱、虚构中伤、恶意诽谤等。</p>
        <p>2、注销 ID、封杀ID、查询IP请求等一律不予受理。</p>
        <p>3、社区上发现色情等违法信息、垃圾广告以及网友虚拟ID之间的攻击谩骂等情况请直接在相关帖文后点击“举报”，或至站务专区版块举报或反映；</p>
        <p>4、自己在社区版块内发表成功的帖子原则上不允许删除，若有特殊原因，请至站务专区“自删申请专帖”提交申请；</p>
        <p>5、百度或谷歌搜索网站的快照问题，请直接联系百度或谷歌客服处理。</p>
        <p><b>二、申请删帖流程</b></p>
        <p>1、请直接点击《凯迪社区删帖申请表》下载表格填写投诉内容（所有选项均为必填），打印出来后请签写当事人/企业/机构亲笔手写签名/盖章；</p>
        <p>
            2、涉及个人的请附个人身份证复印件，并在复印件上签署“仅供凯迪社区删帖申请专用”和当事人亲笔签名；涉及企事业单位的请附加盖公章的营业执照、组织机构代码复印件，并在复印件上签署“仅供凯迪社区删帖申请专用”和法人代表亲笔签名；
        </p>
        <p>3、如有相关法院判决书、公文函件等有力证明材料，务必请一并附上；</p>
        <p>
            4、原则上我们不建议当事方委托第三方进行投诉及申请，若当事方是选择委托律师事务所或个人进行申请的，除了第2项相关材料之外，还须提供当事方亲笔签名的授权委托书，并且受委托方还须提供相关资质证明（律师事务所执业许可证复印件、律师工作执照复印件，个人身份证复印件）；
        </p>
        <p>5、资料准备齐全后，请将以上所有材料寄送至以下地址（请务必使用EMS快递）：</p>
        <p>邮寄地址：海南省海口市海甸岛和平大道一横路钓鱼台别墅B8栋</p>
        <p>收 件 人：凯迪网络办公室</p>
        <p>邮政编码：570208</p>
        <p>联系电话：0898—68555596</p>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
}
</script>

<style lang="less">
.useragreement{
    font-size: 16px;
    padding: 15px;
    p{
        padding: 10px 0;
    }
}
</style>
